import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Button,
  Dialog,
  Select,
  MenuItem,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent,
  DialogActions,
  FormHelperText,
  DialogContentText,
} from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

interface ModalCountryProps {
  isOpen: boolean
  handleClose: () => void
  language: { country: string, value: string }
  handleCountry: (e: any) => void
}

const ModalCountry: React.FC<ModalCountryProps> = (props) => {
  const { isOpen, handleClose, language, handleCountry } = props;

  const countrys = [
    {
      country: 'Argentina',
      language: 'Argentina-Spanish',
    },
    {
      country: 'Bolivia',
      language: 'Bolivia-Spanish',
    },
    {
      country: 'Brazil',
      language: 'Portuguese',
    },
    {
      country: 'Chile',
      language: 'Chile-Spanish',
    },
    {
      country: 'Colombia',
      language: 'Colombia-Spanish',
    },
    {
      country: 'Ecuador',
      language: 'Ecuador-Spanish',
    },
    {
      country: 'Guyana',
      language: 'English',
    },
    {
      country: 'Paraguay',
      language: 'Paraguay-Spanish',
    },
    {
      country: 'Peru',
      language: 'Peru-Spanish',
    },
    {
      country: 'Suriname',
      language: 'Dutch',
    },
    {
      country: 'Uruguay',
      language: 'Uruguay-Spanish',
    },
    {
      country: 'Venezuela',
      language: 'Venezuela-Spanish',
    },
  ];

  return (
    <ThemeProvider theme={darkTheme} >
      <div>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Select an county"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControl required sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-required-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={language}
                  onChange={handleCountry}
                  renderValue={({ country }) => country}
                  style={{ minWidth: '220px' }}
                  required
                  label="Country *"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countrys.map((language) => {
                    return (
                      // @ts-ignore
                      <MenuItem value={language} key={language.language}>{language.country}</MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Select
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>

  )
}

export default ModalCountry;